@media print {
  .btn {
    -webkit-print-color-adjust: exact !important;
  }
}

.btn {
  color: inherit;
  background-color: $primary-light;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  border: none;
  outline: none;
  vertical-align: middle;

  &.rounded {
    border-radius: 8px;
    padding: 13px;
    margin: 10px;
  }

  &.label {
    color: $primary-bold;
    font-size: $fontsize-button;
    text-transform: uppercase;
  }

  //Neumorphism shadows
  &.default {
    box-shadow: 3px 3px 15px $inner-shadow-dark,
      -3px -3px 15px $inner-shadow-light;
  }

  &.pressed {
    box-shadow: inset 3px 3px 15px $inner-shadow-dark,
      inset -3px -3px 15px $inner-shadow-light;

    :first-child,
    span,
    div {
      filter: saturate(0%);
    }
    
    //for texts
    :last-child {
      color: $secondary-light;
    }
  }
}
