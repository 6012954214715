@import "variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $primary-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  align-self: center;
  padding-bottom: 2.5rem;
  margin: 2.5rem 1.8rem;
}

// Reuseable stylings
@mixin grid-2-rows {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}
@mixin grid-2-cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@mixin flex-col {
  display: flex;
  flex-flow: column wrap;
}
@mixin flex-row {
  display: flex;
  flex-flow: row wrap;
}

// General media queries
@media (min-width: $max-s) {
  main {
    margin: 0 3rem;
  }
}
@media (min-width: $max-m) {
  main {
    margin: 0 8rem;
  }
}

@media (min-width: $max-l) {
  main {
    margin: 0 15rem;
    max-width: 1000px;
    min-height: 70vh;
  }
}
