@import "../../styles/index";

nav {
  align-self: first baseline;
  @include flex-col;
  justify-content: space-evenly;

  button.bars {
    cursor: pointer;
    align-self: flex-end;

    display: grid;
    grid-template-rows: repeat(3, 0.5fr);
    gap: 2.5px;
    justify-content: space-between;

    padding: 10px !important;
    min-width: auto;
    // position: absolute;
    // top: 1.3vh;
    // right: 2.4vw;
    position: relative;
    top: 0;
    right: -26px;
    z-index: 9999;
  }

  .menuList {
    background-color: $primary-bold;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 8888;
    opacity: 0.95;

    padding: 35vh 0 0 3rem;

    h5 {
      color: $primary-light;
      float: left;
    }

    ul {
      list-style: none;

      @include flex-col;
      align-items: flex-start;
      align-content: first baseline;
      justify-content: space-around;

      li {
        margin-bottom: 1rem;
        & a {
          text-align: left;
          text-transform: uppercase;
        }
      }
    }
  }
}

.bar {
  background-color: $primary-bold;
  height: 3.5px;
  width: 24px;
  border-radius: 1.3px;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
